import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, CircularProgress } from '@mui/material';

interface UserDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (formData: any) => void;
    mode: 'create' | 'edit';
    initialData?: any;
}

const UserDialog: React.FC<UserDialogProps> = ({ open, onClose, onSubmit, mode, initialData }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        nombre: '',
        role: 'Administrador'
    });

    useEffect(() => {
        if (initialData && mode === 'edit') {
            setFormData({
                email: initialData.email || '',
                nombre: initialData.nombre || '',
                role: initialData.role || 'Administrador'
            });
        } else {
            setFormData({
                email: '',
                nombre: '',
                role: 'Administrador'
            });
        }
    }, [initialData, mode, open]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onSubmit(formData);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{mode === 'create' ? 'Crear Usuario' : 'Editar Usuario'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="email"
                        label="Email"
                        type="email"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        name="nombre"
                        label="Nombre"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.nombre}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        name="role"
                        label="Rol"
                        select
                        fullWidth
                        variant="outlined"
                        value={formData.role}
                        onChange={handleChange}
                        required
                    >
                        <MenuItem value="admin">Administrador</MenuItem>
                        <MenuItem value="comercial">Comercial</MenuItem>
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading}>Cancelar</Button>
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary"
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <CircularProgress size={20} color="inherit" style={{ marginRight: 8 }} />
                                {mode === 'create' ? 'Creando...' : 'Guardando...'}
                            </>
                        ) : (
                            mode === 'create' ? 'Crear' : 'Guardar'
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default UserDialog;
