export const getAuthHeaders = () => {
    const jwt = sessionStorage.getItem('jwt');
    return {
        headers: {
            'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        }
    };
};

export const validateResponse = (response: any) => {
    if (!response) {
        return [];
    }
    
    if (Array.isArray(response)) {
        return response;
    }
    
    if (response.data) {
        return Array.isArray(response.data) ? response.data : [];
    }
    
    return [];
};
