const API_URL = process.env.REACT_APP_API_ENDPOINT;

export interface User {
    id: number;
    email: string;
    nombre: string;
    role: string;
    createdAt: string;
    origenes?: number[];
}

export interface CreateUserRequest {
    email: string;
    nombre: string;
    role: string;
}

export interface UpdateUserRequest {
    nombre: string;
}

export interface UserOrigen {
    userId: number;
    origenId: number;
}

const getHeaders = () => ({
    'Authorization': `Bearer ${sessionStorage.getItem('jwt')}`,
    'Content-Type': 'application/json'
});

export async function getUsers() {
    const jwt = sessionStorage.getItem('jwt');
    if (!jwt) {
        throw new Error('401: No authentication token found');
    }

    try {
        const response = await fetch(
            `${API_URL}/api/users`,
            { headers: getHeaders() }
        );

        if (response.status === 401) {
            sessionStorage.removeItem('jwt');
            throw new Error('401: Unauthorized');
        }

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
}

export async function createUser(data: CreateUserRequest) {
    const response = await fetch(
        `${API_URL}/api/users/invite`,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(data)
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function updateUser(id: number, data: UpdateUserRequest) {
    const response = await fetch(
        `${API_URL}/api/users/${id}`,
        {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(data)
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function deleteUser(id: number) {
    const response = await fetch(
        `${API_URL}/api/users/${id}`,
        {
            method: 'DELETE',
            headers: getHeaders()
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function assignOrigenes(userId: number, origenesIds: number[]) {
    const response = await fetch(
        `${API_URL}/api/users/${userId}/assign-origenes`,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ origenesIds })
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function revokeOrigenes(userId: number, origenesIds: number[]) {
    const response = await fetch(
        `${API_URL}/api/users/${userId}/revoke-origenes`,
        {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify({ origenesIds })
        }
    );

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
}

export async function getUserOrigenes(userId: number) {
    try {
        const response = await fetch(
            `${API_URL}/api/users/${userId}/origenes`,
            { headers: getHeaders() }
        );

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching user origenes:', error);
        return [];
    }
}
