import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import LogoutIcon from '@mui/icons-material/Logout';
import logo from '../assets/img/logo_garex.png';
import { SegFooter } from '../components/SegFooter/SegFooter';
import Gestiones from './Gestiones';
import Usuarios from './Usuarios';
import 'react-toastify/dist/ReactToastify.css';

const NavBar = styled.nav`
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 0.9rem;
  color: #333;
  display: flex;
  justify-content: flex-start;
  flex: 1;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
`;

const NavButton = styled.button`
  margin-right: 10px;
  border-radius: 20px;
  border: 1px solid #ccc;
  background-color: #eaeaea;
  padding: 6px 12px;
  color: #333;
  cursor: pointer;
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 15px;
  border: 1px solid #dc3545;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 0.75rem;

  &:hover {
    background-color: #bd2130;
  }

  svg {
    font-size: 16px;
  }
`;

export default function BackOffice() {
    const [body, setBody] = React.useState(0);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const jwt = sessionStorage.getItem('jwt');
        if (!jwt) {
            navigate('/login', { replace: true });
            return;
        }

        try {
            const decoded = jwtDecode(jwt) as { role?: string };
            setIsAdmin(decoded.role === 'admin');
        } catch (error) {
            console.error('Error decoding JWT:', error);
            navigate('/login', { replace: true });
        }
    }, [navigate]);

    const handleLogout = () => {
        sessionStorage.removeItem('jwt');
        window.location.href = '/login';
    };

    return (
        <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <ToastContainer position="top-right" />
            <Header>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={logo} alt="Garex Assist" style={{ height: '50px', marginRight: '20px' }} />
                    <NavBar>
                        <NavButton onClick={() => setBody(0)}>Gestiones</NavButton>
                        {isAdmin && <NavButton onClick={() => setBody(1)}>Usuarios</NavButton>}
                        <NavButton disabled>Reportería</NavButton>
                    </NavBar>
                </div>
                <LogoutButton onClick={handleLogout}>
                    <LogoutIcon />
                    Cerrar sesión
                </LogoutButton>
            </Header>
            <div style={{ flex: 1 }}>
                {body === 0 && <Gestiones />}
                {body === 1 && isAdmin && <Usuarios />}
            </div>
            <SegFooter
                img={logo}
                text='Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos Aires, Argentina.'
                linkAdicional="https://segurarse.com.ar/politicas-de-privacidad"
                textAdicional="Políticas de Privacidad"
            />
        </div>
    );
}