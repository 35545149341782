import React, { useEffect, useState } from 'react';
import { 
    DataGrid, 
    GridColDef, 
    GridActionsCellItem, 
    GridToolbar
} from '@mui/x-data-grid';
import * as locales from '@mui/x-data-grid/locales';
import DeleteIcon from '@mui/icons-material/Delete';  // Corrección en la importación
import styled from '@emotion/styled';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import {
    getOrigenes, createOrigen, updateOrigen, deleteOrigen,
    getComercios, createComercio, updateComercio, deleteComercio,
    getSucursales, createSucursal, updateSucursal, deleteSucursal,
    getVendedores, createVendedor, updateVendedor, deleteVendedor
} from '../services/GestionesService';
import CreateDialog from '../components/CreateDialog';
import { Origen, Comercio, Sucursal } from '../types';

const StyledTitle = styled.h1`
    color: #333;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #2C7DFD;
`;

export default function Gestiones() {
    const [activeTable, setActiveTable] = useState<'origenes'|'comercios'|'sucursales'|'vendedores'>('origenes');
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [origenOptions, setOrigenOptions] = useState<any[]>([]);
    const [comercioOptions, setComercioOptions] = useState<any[]>([]);
    const [sucursalOptions, setSucursalOptions] = useState<any[]>([]);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#2C7DFD' }
            },
        },
        esES // Configuración en español
    );

    const loadData = async () => {
        setLoading(true);
        try {
            const result = await (async () => {
                switch (activeTable) {
                    case 'comercios': return await getComercios();
                    case 'sucursales': return await getSucursales();
                    case 'vendedores': return await getVendedores();
                    default: return await getOrigenes();
                }
            })();

            // Asegurarse de que data es un array y tiene un id válido
            const validData = (result || []).filter((item: any) => item && (item.id || item.Id));
            setData(validData);

            // También validar las opciones
            if (!origenOptions.length) {
                const origenes = await getOrigenes();
                setOrigenOptions(origenes.filter((o: Origen) => o && o.id));
            }
            if (!comercioOptions.length) {
                const comercios = await getComercios();
                setComercioOptions(comercios.filter((c: Comercio) => c && c.id));
            }
            if (!sucursalOptions.length) {
                const sucursales = await getSucursales();
                setSucursalOptions(sucursales.filter((s: Sucursal) => s && s.id));
            }
        } catch (error) {
            console.error('Error loading data:', error);
            setData([]); // En caso de error, asegurar que data es un array vacío
        }
        setLoading(false);
    };

    useEffect(() => {
        loadData();
    }, [activeTable]);

    const handleCreate = async (data: any) => {
        try {
            switch (activeTable) {
                case 'comercios':
                    if (!data.origenId) {
                        alert('Debe seleccionar un origen');
                        return;
                    }
                    await createComercio(data);
                    break;
                case 'sucursales':
                    if (!data.comercioId) {
                        alert('Debe seleccionar un comercio');
                        return;
                    }
                    await createSucursal(data);
                    break;
                case 'vendedores':
                    if (!data.sucursalId) {
                        alert('Debe seleccionar una sucursal');
                        return;
                    }
                    await createVendedor(data);
                    break;
                default:
                    await createOrigen(data);
            }
            await loadData();
        } catch (error) {
            console.error('Error creating:', error);
        }
    };

    const handleCellEdit = async (newRow: any, oldRow: any) => {
        try {
            let response;
            switch (activeTable) {
                case 'comercios': 
                    response = await updateComercio(newRow.id, newRow);
                    break;
                case 'sucursales':
                    response = await updateSucursal(newRow.id, newRow);
                    break;
                case 'vendedores':
                    response = await updateVendedor(newRow.id, newRow);
                    break;
                default:
                    response = await updateOrigen(newRow.id, {
                        id: newRow.id,
                        nombre: newRow.nombre,
                        descripcion: newRow.descripcion,
                        activo: newRow.activo ?? false
                    });
            }
            
            await loadData();
            return response;
        } catch (error) {
            console.error('Error updating:', error);
            return oldRow;
        }
    };

    const handleDelete = async (id: number) => {
        if (!window.confirm(`¿Está seguro de eliminar este ${activeTable.slice(0, -1)}?`)) return;
        
        try {
            switch (activeTable) {
                case 'comercios': await deleteComercio(id); break;
                case 'sucursales': await deleteSucursal(id); break;
                case 'vendedores': await deleteVendedor(id); break;
                default: await deleteOrigen(id);
            }
            await loadData();
        } catch (error) {
            console.error('Error deleting:', error);
        }
    };

    const getColumns = (): GridColDef[] => {
        const commonColumns: GridColDef[] = [
            { field: 'id', headerName: 'ID', width: 70, editable: false },
            { field: 'activo', headerName: 'Activo', width: 100, type: 'boolean', editable: true }
        ];

        const actionColumn: GridColDef = {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Eliminar"
                    onClick={() => handleDelete(Number(params.id))}
                />
            ]
        };

        switch (activeTable) {
            case 'comercios':
                return [
                    ...commonColumns,
                    { field: 'nombre', headerName: 'Nombre', width: 200, editable: true },
                    { field: 'descripcion', headerName: 'Descripción', width: 300, editable: true },
                    {
                        field: 'origenId',
                        headerName: 'Origen',
                        width: 200,
                        editable: true,
                        type: 'singleSelect',
                        valueOptions: origenOptions.map(o => ({ value: o.id, label: o.nombre }))
                    },
                    actionColumn
                ];
            case 'sucursales':
                return [
                    ...commonColumns,
                    { field: 'numero', headerName: 'Número', width: 100, editable: true },
                    { field: 'nombre', headerName: 'Nombre', width: 200, editable: true },
                    { field: 'direccion', headerName: 'Dirección', width: 300, editable: true },
                    {
                        field: 'comercioId',
                        headerName: 'Comercio',
                        width: 200,
                        editable: true,
                        type: 'singleSelect',
                        valueOptions: comercioOptions.map(c => ({ value: c.id, label: c.nombre }))
                    },
                    actionColumn
                ];
            case 'vendedores':
                return [
                    ...commonColumns,
                    { field: 'codigo', headerName: 'Código', width: 100, editable: true },
                    {
                        field: 'sucursalId',
                        headerName: 'Sucursal',
                        width: 200,
                        editable: true,
                        type: 'singleSelect',
                        valueOptions: sucursalOptions.map(s => ({ value: s.id, label: s.nombre }))
                    },
                    actionColumn
                ];
            default:
                return [
                    ...commonColumns,
                    { field: 'nombre', headerName: 'Nombre', width: 200, editable: true },
                    { field: 'descripcion', headerName: 'Descripción', width: 300, editable: true },
                    actionColumn
                ];
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <div style={{ padding: '20px' }}>
                <StyledTitle>Gestión de {activeTable.charAt(0).toUpperCase() + activeTable.slice(1)}</StyledTitle>
                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        {['origenes', 'comercios', 'sucursales', 'vendedores'].map((table) => (
                            <button
                                key={table}
                                onClick={() => setActiveTable(table as typeof activeTable)}
                                style={{
                                    fontSize: '0.8rem',
                                    borderRadius: '3px',
                                    border: '1px solid #ccc',
                                    backgroundColor: activeTable === table ? '#2C7DFD' : '#eaeaea',
                                    color: activeTable === table ? 'white' : '#333',
                                    marginRight: '6px',
                                    padding: '4px 8px',
                                    cursor: 'pointer'
                                }}
                            >
                                {table.charAt(0).toUpperCase() + table.slice(1)}
                            </button>
                        ))}
                    </div>
                    <button
                        onClick={() => setCreateDialogOpen(true)}
                        style={{
                            fontSize: '0.8rem',
                            borderRadius: '3px',
                            border: '1px solid #2C7DFD',
                            backgroundColor: '#2C7DFD',
                            color: 'white',
                            padding: '4px 8px',
                            cursor: 'pointer'
                        }}
                    >
                        + Nuevo
                    </button>
                </div>
                <CreateDialog
                    open={createDialogOpen}
                    onClose={() => setCreateDialogOpen(false)}
                    onSubmit={handleCreate}
                    type={activeTable}
                    options={{
                        origenes: origenOptions,
                        comercios: comercioOptions,
                        sucursales: sucursalOptions
                    }}
                />
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={data}
                        columns={getColumns()}
                        loading={loading}
                        getRowId={(row) => row.Id || row.id} // Agregar esta línea para manejar diferentes casos de ID
                        slots={{ 
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                csvOptions: { disableToolbarButton: true }, // Ocultar botón de exportar
                                printOptions: { disableToolbarButton: true }, // Ocultar botón de imprimir
                            },
                        }}
                        density="compact" // Densidad compacta por defecto
                        editMode="cell"
                        processRowUpdate={handleCellEdit}
                        onProcessRowUpdateError={(error) => {
                            console.error('Error updating row:', error);
                        }}
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: { 
                                paginationModel: { 
                                    pageSize: 10,
                                    page: 0
                                } 
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        localeText={locales.esES.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
            </div>
        </ThemeProvider>
    );
}