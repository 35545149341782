import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner } from 'react-bootstrap';
import { SegHeaderLogo } from '../components/SegHeaderLogo/SegHeaderLogo';
import { SegFooter } from '../components/SegFooter/SegFooter';
import '../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import logo from "../assets/img/logo_garex.png"
import { SegH1 } from '../components/SegH1/SegH1';
import { SegButton } from '../components/SegButton/SegButton';
import styled from "@emotion/styled";
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmarCarta, ObtenerCarta } from '../services/Carta';

const StyledH1 = styled(SegH1)`
    text-align: center;
    margin: 34px auto;
    padding: 0 15px;
`;

const StyledDiv = styled.div`
    margin: 20px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

// Estilos personalizados para el contenedor del checkbox
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 20px auto;
`;

// Estilos personalizados para el checkbox
const StyledCheckbox = styled.input`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  accent-color: #007bff; /* Cambia el color del checkbox para navegadores que soportan 'accent-color' */
`;

// Estilos para la etiqueta del checkbox
const CheckboxLabel = styled.label`
  font-size: 14px;
  color: #333;
  cursor: pointer; /* Cambia el cursor al pasar sobre la etiqueta */
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 80%;
  margin: 20px auto;
`;

const Th = styled.th`
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
  text-align: left;
`;

const Td = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 14px;
  border-radius: 8px;
`;

const Carta = () => {
    const [cargandoGeneral, setCargandoGeneral] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const [cartaAceptacionConfirmada, setCartaAceptacionConfirmada] = useState(false);
    const [cartaInexistente, setCartaInexistente] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [datosGarex, setDatosGarex] = useState(Object || null);
    const [cartaId, setCartaId] = useState("");

    const { id } = useParams();
    const navigate = useNavigate();

    const fetchCarta = async () => {
        try {
            if (id) {
                const pathSegments = window.location.pathname.split('/');
                const encodedId = pathSegments[pathSegments.length - 1];
                setCartaId(encodedId);
                setCargandoGeneral(true);
                ObtenerCarta(encodedId).then((response) => {
                    try {
                        var data = JSON.parse(response.data);
                        if (data.Success) {
                            setCartaAceptacionConfirmada(data.CartaAceptacionConfirmada);
                            setDatosGarex(data);
                        } else {
                            setCartaInexistente(true);
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setCargandoGeneral(false);
                });
            }
        } catch (error) {
            setCartaInexistente(true);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCarta();
    }, []);

    const handleSubmit = () => {
        setSubmitLoading(true);
        try {
            if (!cartaAceptacionConfirmada && checkboxChecked && cartaId) {
                ConfirmarCarta(cartaId).then((response) => {
                    try {
                        var data = JSON.parse(response.data);
                        if (data.Success) {
                            setCartaAceptacionConfirmada(true);
                            navigate('/aceptar-tyc/gracias', { state: { titulo: "Gracias", texto: "Aceptaste los términos y condiciones." } });
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    setSubmitLoading(false);
                })
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="container sticky-top pt-3 pb-3 bg-white" style={{ maxWidth: "100%" }}>
                <div className="row">
                    <div className="col-md-6 offset-md-3 text-center"> {/* Centra el contenido en la columna */}
                        <SegHeaderLogo img={logo} imgPartner={""} altPartner='' alt="Garex Assist" width="160px" />
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: "" }}>
                {cargandoGeneral ?
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spinner animation="border" />
                    </div>
                    :
                    !cartaAceptacionConfirmada && !cartaInexistente ?
                        <>
                            <StyledH1 title='Aceptá los términos y condiciones de tu garantía extendida'></StyledH1>
                            <Table>
                                <thead>
                                    <tr>
                                        <Th>Marca</Th>
                                        <Th>Modelo</Th>
                                        <Th>Monto</Th>
                                    </tr>
                                </thead>
                                {datosGarex &&
                                    <tbody>

                                        <tr>
                                            <Td>{datosGarex.ArticuloMarca}</Td>
                                            <Td>{datosGarex.ArticuloModelo}</Td>
                                            <Td>$ {datosGarex.ArticuloPrecio}</Td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                            <StyledDiv>
                                <p style={{ textAlign: "justify" }}>
                                    Los datos que facilito son de carácter obligatorio ya que es imprescindible identificarme para la aceptación de esta
                                    solicitud y su posterior ejecución. Estos datos y cualquier otro que sea obtenido por AG Global Services podrán ser incorporados a
                                    los registros del mismo. Presto mi consentimiento expreso a AG Global Services. para que: i) trate, ceda y/o transfiera mis datos
                                    y/o legajos a sus afiliadas, subsidiarias, compañías vinculadas y/o terceros autorizando a hacerlo a las mencionadas
                                    empresas y/o entre sí, con fines identificatorios; ii) mis datos sean alojados y tratados en servidores propios o de
                                    terceros, en Argentina u otros países que podrían no tener legislaciones o niveles de protección adecuados. En ese
                                    caso AG Global Services, intentará lograr un acuerdo con el propietario del servidor para lograr niveles de protección adecuados; y ii)
                                    que, en este proceso de contratación, los datos de mi Documento Nacional de Identidad sean confrontados con la
                                    base de datos del Registro Nacional de las Personas para validar mi identidad y verificar la vigencia de mi DNI. Los
                                    datos confrontados serán destruidos una vez realizada la verificación. Tomo conocimiento que podré ejercer los
                                    derechos de acceso, rectificación y supresión de mis datos en cualquier momento y a mi sola solicitud ante el Banco.
                                    La Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley N°25.326, tiene la
                                    atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por
                                    incumplimiento de las normas vigentes en materia de protección de datos personales.
                                </p>
                                <p style={{ textAlign: "justify" }}>
                                    Al hacer click en "Continuar", autorizás a Santander Consumer S.A. a ceder tu celular a la base de datos de
                                    AG Global Services. A los fines de contratar la/s asistencia/s con esta empresa. De lo contrario, no podrás contratar
                                    la asistencia “Garex Assist”. Recordá que tenés derecho al acceso, rectificación, actualización, y cuando corresponda, supresión de
                                    tus datos personales en cualquier momento. LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter
                                    de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan
                                    quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de
                                    datos personales. Tené en cuenta que es posible que un seguro que hayas contratado incluya alguna de estas
                                    asistencias. Estas pueden ser o no complementarias.
                                </p>
                                <CheckboxContainer>
                                    <StyledCheckbox
                                        name="acepta"
                                        type="checkbox"
                                        id="acepta"
                                        checked={checkboxChecked}
                                        onChange={(e) => setCheckboxChecked(e.target.checked)}
                                    />
                                    <CheckboxLabel htmlFor="acepta">Acepto los términos y condiciones</CheckboxLabel>
                                </CheckboxContainer>
                                <SegButton
                                    type='button'
                                    label='Continuar'
                                    disabled={!checkboxChecked}
                                    onClick={handleSubmit}
                                    loading={submitLoading} />
                            </StyledDiv>
                        </>
                        : cartaInexistente ?
                            <StyledDiv>
                                <p>La carta que intenta visualizar no existe.</p>
                            </StyledDiv>
                            :
                            <StyledDiv>
                                <p>Los términos y condiciones de esta Garantía Extendida ya fueron aceptados.</p>
                            </StyledDiv>
                }
            </div >
            <div>
                <SegFooter
                    img={logo}
                    text='Av. Bartolomé Mitre 1249 P.B. "B", Florida Oeste, Buenos Aires, Argentina.'
                    linkAdicional="https://segurarse.com.ar/politicas-de-privacidad"
                    textAdicional="Políticas de Privacidad"
                />
            </div>
        </>
    );
};

export default Carta;