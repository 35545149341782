import React, { useEffect, useState } from 'react';
import { 
    DataGrid, 
    GridColDef, 
    GridActionsCellItem,
    GridToolbar
} from '@mui/x-data-grid';
import * as locales from '@mui/x-data-grid/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import { toast } from 'react-toastify';
import UserDialog from '../components/UserDialog';
import { 
    getUsers, 
    createUser, 
    updateUser, 
    deleteUser,
    type User,
    type CreateUserRequest,
    type UpdateUserRequest 
} from '../services/UsersService';

const StyledTitle = styled.h1`
    color: #333;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 2px solid #2C7DFD;
`;

const Usuarios: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMode, setDialogMode] = useState<'create' | 'edit'>('create');
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const theme = createTheme(
        {
            palette: {
                primary: { main: '#2C7DFD' }
            },
        },
        esES
    );

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const data = await getUsers();
            setUsers(Array.isArray(data) ? data : []);
        } catch (error: any) {
            if (error?.response?.status === 401 || error?.message?.includes('401')) {
                // Redirigir al login si no está autenticado
                sessionStorage.removeItem('jwt');
                window.location.href = '/login';
                return;
            }
            toast.error('Error al cargar usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const jwt = sessionStorage.getItem('jwt');
        if (!jwt) {
            window.location.href = '/login';
            return;
        }
        fetchUsers();
    }, []);

    const handleCreate = async (formData: CreateUserRequest) => {
        try {
            await createUser(formData);
            toast.success('Usuario invitado exitosamente');
            setDialogOpen(false);
            fetchUsers();
        } catch (error) {
            toast.error('Error al crear usuario');
        }
    };

    const handleUpdate = async (formData: UpdateUserRequest) => {
        if (!selectedUser) return;

        try {
            await updateUser(selectedUser.id, formData);
            toast.success('Usuario actualizado exitosamente');
            setDialogOpen(false);
            fetchUsers();
        } catch (error) {
            toast.error('Error al actualizar usuario');
        }
    };

    const handleDelete = async (id: number) => {
        if (!window.confirm('¿Está seguro de eliminar este usuario?')) return;

        try {
            await deleteUser(id);
            toast.success('Usuario eliminado exitosamente');
            fetchUsers();
        } catch (error) {
            toast.error('Error al eliminar usuario');
        }
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'nombre', headerName: 'Nombre', width: 200 },
        { field: 'role', headerName: 'Rol', width: 130 },
        { 
            field: 'createdAt', 
            headerName: 'Fecha Creación', 
            width: 180,
            valueFormatter: (params: { value: string }) => new Date(params.value).toLocaleDateString()
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Acciones',
            width: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="Editar"
                    onClick={() => {
                        setSelectedUser(params.row);
                        setDialogMode('edit');
                        setDialogOpen(true);
                    }}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Eliminar"
                    onClick={() => handleDelete(params.row.id)}
                />
            ]
        }
    ];

    return (
        <ThemeProvider theme={theme}>
            <div style={{ padding: '20px' }}>
                <StyledTitle>Gestión de Usuarios</StyledTitle>
                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        onClick={() => {
                            setDialogMode('create');
                            setSelectedUser(null);
                            setDialogOpen(true);
                        }}
                        style={{
                            fontSize: '0.8rem',
                            borderRadius: '3px',
                            border: '1px solid #2C7DFD',
                            backgroundColor: '#2C7DFD',
                            color: 'white',
                            padding: '4px 8px',
                            cursor: 'pointer'
                        }}
                    >
                        + Nuevo Usuario
                    </button>
                </div>

                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid
                        rows={users}
                        columns={columns}
                        loading={loading}
                        slots={{ 
                            toolbar: GridToolbar 
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                                csvOptions: { disableToolbarButton: true },
                                printOptions: { disableToolbarButton: true },
                            },
                        }}
                        density="compact"
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: { 
                                paginationModel: { 
                                    pageSize: 10,
                                    page: 0
                                } 
                            },
                        }}
                        pageSizeOptions={[5, 10, 25, 50, 100]}
                        localeText={locales.esES.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>

                <UserDialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    onSubmit={dialogMode === 'create' ? handleCreate : handleUpdate}
                    mode={dialogMode}
                    initialData={selectedUser || undefined}
                />
            </div>
        </ThemeProvider>
    );
};

export default Usuarios;
