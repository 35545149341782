import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuthHeaders, validateResponse } from './Helpers';

// Interceptor para manejar 401s
axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response?.status === 401) {
            // Solo si no estamos ya en /login
            if (!window.location.pathname.includes('/login')) {
                sessionStorage.removeItem('jwt');
                toast.error('Su sesión ha expirado. Por favor, inicie sesión nuevamente.');
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

// Origenes
export async function getOrigenes() {
    try {
        const resp = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/origenes/all',
            getAuthHeaders()
        );
        return validateResponse(resp.data);
    } catch (error) {
        console.error('Error fetching origenes:', error);
        return [];
    }
}

export async function createOrigen(data: any) {
    const resp = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/origenes',  // Corregido
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function updateOrigen(id: number, data: any) {
    const resp = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/origenes/${id}`,  // Corregido
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function deleteOrigen(id: number) {
    const resp = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/origenes/${id}`,
        getAuthHeaders()
    );
    return resp.data;
}

// Comercios
export async function getComercios() {
    try {
        const resp = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/comercios/all',
            getAuthHeaders()
        );
        return validateResponse(resp.data);
    } catch (error) {
        console.error('Error fetching comercios:', error);
        return [];
    }
}

export async function createComercio(data: any) {
    const resp = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/comercios',
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function updateComercio(id: number, data: any) {
    const resp = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/comercios/${id}`,
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function deleteComercio(id: number) {
    const resp = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/comercios/${id}`,
        getAuthHeaders()
    );
    return resp.data;
}

// Sucursales
export async function getSucursales() {
    try {
        const resp = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/sucursales/all',
            getAuthHeaders()
        );
        return validateResponse(resp.data);
    } catch (error) {
        console.error('Error fetching sucursales:', error);
        return [];
    }
}

export async function createSucursal(data: any) {
    const resp = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/sucursales',
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function updateSucursal(id: number, data: any) {
    const resp = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/sucursales/${id}`,
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function deleteSucursal(id: number) {
    const resp = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/sucursales/${id}`,
        getAuthHeaders()
    );
    return resp.data;
}

// Vendedores
export async function getVendedores() {
    try {
        const resp = await axios.get(
            process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/vendedores/all',
            getAuthHeaders()
        );
        return validateResponse(resp.data);
    } catch (error) {
        console.error('Error fetching vendedores:', error);
        return [];
    }
}

export async function createVendedor(data: any) {
    const resp = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/api/backoffice/vendedores',
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function updateVendedor(id: number, data: any) {
    const resp = await axios.put(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/vendedores/${id}`,
        data,
        getAuthHeaders()
    );
    return resp.data;
}

export async function deleteVendedor(id: number) {
    const resp = await axios.delete(
        process.env.REACT_APP_API_ENDPOINT + `/api/backoffice/vendedores/${id}`,
        getAuthHeaders()
    );
    return resp.data;
}