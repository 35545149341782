import { Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface PrivateRouteProps {
    children: React.ReactNode;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
    const navigate = useNavigate();
    const jwt = sessionStorage.getItem('jwt');
    
    useEffect(() => {
        if (!jwt) {
            navigate('/login', { replace: true });
        }
    }, [jwt, navigate]);

    if (!jwt) {
        return <Navigate to="/login" replace />;
    }

    return <>{children}</>;
}
