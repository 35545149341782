import React, { useState, useEffect } from 'react';
import BackOffice from './BackOffice';
import styled from '@emotion/styled';
import logo from '../assets/img/logo_garex.png';
import { SegInput } from '../components/SegInput/SegInput';
import { SegButton } from '../components/SegButton/SegButton';
import { Navigate, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;
`;

const Logo = styled.img`
  height: 60px;
  margin-bottom: 30px;
`;

const FormBox = styled.div`
  width: 300px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0,0,0,0.15);
  background-color: #fff;
`;

function Admin() {
    const [email, setEmail] = useState('');
    const [responseText, setResponseText] = useState('');
    const [loginLoading, setLoginLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        const jwt = sessionStorage.getItem('jwt');
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        // Si tenemos token en la URL, procesarlo y redirigir
        if (token) {
            sessionStorage.setItem('jwt', token);
            navigate('/admin');
            toast.success('Sesión iniciada correctamente');
            return;
        }

        // Si tenemos JWT y NO estamos en /login, dejar pasar
        if (jwt && location.pathname !== '/login') {
            return;
        }

        // Si tenemos JWT y estamos en /login, redirigir a /admin
        if (jwt && location.pathname === '/login') {
            navigate('/admin');
            return;
        }

        // Si no hay JWT ni token, nos quedamos en login
    }, [navigate, location]);

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoginLoading(true);
        try {
            const response = await fetch(apiUrl + '/api/users/login-by-email', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email })
            });
            if (response.ok) {
                const data = await response.json();
                if (data.success) {
                    setResponseText(data.message);
                    toast.success('Se ha enviado un email con el link de acceso');
                } else {
                    setResponseText('Error en la autenticación');
                    toast.error(data.message || 'Error al intentar iniciar sesión');
                }
            } else {
                setResponseText("Error al ingresar. Contacte a su administrador.");
                toast.error('Error al intentar iniciar sesión');
            }
        } catch (err) {
            toast.error('Error en la solicitud');
            setResponseText('Error en la solicitud');
        } finally {
            setLoginLoading(false);
        }
    };

    // Si no hay JWT, mostrar login
    if (!sessionStorage.getItem('jwt')) {
        return (
            <LoginContainer>
                <Logo src={logo} alt="Garex Assist" />
                <FormBox>
                    {!responseText ? (
                        <form onSubmit={handleLogin}>
                            <SegInput
                                label="Email"
                                type="email"
                                name="email"
                                placeHolder="Ingresa tu correo"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                width={100}
                            />
                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <SegButton
                                    type="submit"
                                    label="Login"
                                    disabled={!email || loginLoading}
                                    loading={loginLoading}
                                />
                            </div>
                        </form>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <p>{responseText}</p>
                        </div>
                    )}
                </FormBox>
            </LoginContainer>
        );
    }

    // Si hay JWT, mostrar BackOffice
    return <BackOffice />;
}

export default Admin;