import { Routes, Route, Navigate } from 'react-router-dom';
import App from '../App';
import Error from '../Error';
import Carta from '../pages/Carta';
import { Gracias } from '../Gracias';
import Admin from '../pages/Admin';
import BackOffice from '../pages/BackOffice';
import Gestiones from '../pages/Gestiones';
import PrivateRoute from '../components/PrivateRoute';

const AppRouter = () => {
    return (
        <Routes>
            <Route path="/:comercio/:sucursal" element={<App />} />
            <Route path='/:comercio/:sucursal/error' element={<Error />} />
            <Route path='/aceptar-tyc/:id' element={<Carta />} />
            <Route path='/aceptar-tyc/gracias' element={<Gracias />} />
            <Route path='/login' element={<Admin />} />
            <Route path='/admin' element={
                <PrivateRoute>
                    <BackOffice />
                </PrivateRoute>
            } />
            <Route path='/gestiones' element={
                <PrivateRoute>
                    <Gestiones />
                </PrivateRoute>
            } />
            {/* Redirección por defecto a /admin si hay JWT, sino a /login */}
            <Route path="/" element={
                sessionStorage.getItem('jwt') 
                    ? <Navigate to="/admin" replace />
                    : <Navigate to="/login" replace />
            } />
        </Routes>
    );
};

export default AppRouter;
