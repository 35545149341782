import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    CircularProgress
} from '@mui/material';

interface CreateDialogProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: any) => void;
    type: 'origenes' | 'comercios' | 'sucursales' | 'vendedores';
    options?: {
        origenes?: any[];
        comercios?: any[];
        sucursales?: any[];
    };
}

export default function CreateDialog({ open, onClose, onSubmit, type, options }: CreateDialogProps) {
    const [formData, setFormData] = React.useState<any>({
        nombre: '',
        descripcion: '',
        activo: true,
        origenId: '',
        comercioId: '',
        sucursalId: '',
        numero: '',
        direccion: '',
        codigo: ''
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (field: string) => (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
    ) => {
        setFormData({
            ...formData,
            [field]: event.target.value
        });
    };

    const handleCheckboxChange = (field: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setFormData({
            ...formData,
            [field]: event.target.checked
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await onSubmit(formData);
            onClose();
        } finally {
            setLoading(false);
        }
    };

    const renderFields = () => {
        switch (type) {
            case 'origenes':
                return (
                    <>
                        <TextField
                            label="Nombre"
                            value={formData.nombre}
                            onChange={handleChange('nombre')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Descripción"
                            value={formData.descripcion}
                            onChange={handleChange('descripcion')}
                            fullWidth
                            margin="normal"
                        />
                    </>
                );
            case 'comercios':
                return (
                    <>
                        <TextField
                            label="Nombre"
                            value={formData.nombre}
                            onChange={handleChange('nombre')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Descripción"
                            value={formData.descripcion}
                            onChange={handleChange('descripcion')}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Origen</InputLabel>
                            <Select
                                value={formData.origenId}
                                onChange={handleChange('origenId')}
                                label="Origen"
                            >
                                {options?.origenes?.map(origen => (
                                    <MenuItem key={origen.id} value={origen.id}>
                                        {origen.nombre}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                );
            case 'sucursales':
                return (
                    <>
                        <TextField
                            label="Número"
                            type="number"
                            value={formData.numero}
                            onChange={handleChange('numero')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Nombre"
                            value={formData.nombre}
                            onChange={handleChange('nombre')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <TextField
                            label="Dirección"
                            value={formData.direccion}
                            onChange={handleChange('direccion')}
                            fullWidth
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Comercio</InputLabel>
                            <Select
                                value={formData.comercioId}
                                onChange={handleChange('comercioId')}
                                label="Comercio"
                            >
                                {options?.comercios?.map(comercio => (
                                    <MenuItem key={comercio.id} value={comercio.id}>
                                        {comercio.nombre}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                );
            case 'vendedores':
                return (
                    <>
                        <TextField
                            label="Código"
                            type="number"
                            value={formData.codigo}
                            onChange={handleChange('codigo')}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Sucursal</InputLabel>
                            <Select
                                value={formData.sucursalId}
                                onChange={handleChange('sucursalId')}
                                label="Sucursal"
                            >
                                {options?.sucursales?.map(sucursal => (
                                    <MenuItem key={sucursal.id} value={sucursal.id}>
                                        {`${sucursal.nombre} (${sucursal.numero})`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </>
                );
            default:
                return null;
        }
    };

    const getDialogTitle = () => {
        const entityNames = {
            origenes: 'Origen',
            comercios: 'Comercio',
            sucursales: 'Sucursal',
            vendedores: 'Vendedor'
        };
        return `Crear nuevo ${entityNames[type]}`;
    };

    // Reset form when dialog opens/closes or type changes
    React.useEffect(() => {
        setFormData({
            nombre: '',
            descripcion: '',
            activo: true,
            origenId: '',
            comercioId: '',
            sucursalId: '',
            numero: '',
            direccion: '',
            codigo: ''
        });
    }, [open, type]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            <DialogContent>
                {renderFields()}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.activo}
                            onChange={handleCheckboxChange('activo')}
                        />
                    }
                    label="Activo"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={loading}>Cancelar</Button>
                <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    color="primary"
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <CircularProgress size={20} color="inherit" style={{ marginRight: 8 }} />
                            Creando...
                        </>
                    ) : (
                        'Crear'
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
